import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CardGroup = makeShortcode("CardGroup");
const MiniCard = makeShortcode("MiniCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentViewState.figmaUrl.android} codeUrl={checklists.componentViewState.codeUrl.android} checklists={checklists.componentViewState.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Views are responsible for managing their own state. Android View displays different content based on its state. View state in Legion is implemented based on base components forking from `}<a parentName="p" {...{
        "href": "https://github.com/Kennyc1012/MultiStateView"
      }}>{`Kennyc1012/MultiStateView`}</a>{` with some modification and some improvement.`}</p>
    <br />
Legion provides 4 different states, where we can customize activities to each layout state.
    <h3>{`Default Content`}</h3>
    <p>{`This is default state provided by Legion.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/viewstate/android-1.png",
      "width": 150,
      "height": 300,
      "align": "center",
      "alt": "viewstate android default"
    }}></img>
    <h3>{`Empty`}</h3>
    <p>{`This is state that we can use when we have nothing to show in view.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/viewstate/android-3.png",
      "width": 150,
      "height": 300,
      "align": "center",
      "alt": "viewstate android empty"
    }}></img>
    <h3>{`Error`}</h3>
    <p>{`This is state that we can use when we have an error activity in view.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/viewstate/android-4.png",
      "width": 150,
      "height": 300,
      "align": "center",
      "alt": "viewstate android error"
    }}></img>
    <h3>{`Loading`}</h3>
    <p>{`This is state that we can use when we still waiting for an activity in view.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/viewstate/android-2.png",
      "width": 150,
      "height": 300,
      "align": "center",
      "alt": "viewstate android loading"
    }}></img>
    <div className="divi" />
    <h2>{`How to use`}</h2>
    <h4>{`Static usage in XML`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"layout_sample.xml\"",
        "title": "\"layout_sample.xml\""
      }}>{`<com.telkom.legion.component.viewstate.LgnViewState
    android:id="@+id/msvSample"
    android:layout_width="match_parent"
    android:layout_height="250dp"
    app:loadingView="@layout/layout_sample_one"
    app:errorView="@layout/layout_sample_two"
    app:emptyView="@layout/layout_sample_three"
    app:viewState="content">
    <FrameLayout
        android:layout_width="match_parent"
        android:layout_height="match_parent"
        android:background="?attr/info_normal">
        <TextView
            android:layout_width="wrap_content"
            android:layout_height="wrap_content"
            android:text="CONTENT"
            android:textAppearance="?attr/bodyLargeMedium"
            android:layout_gravity="center"/>
    </FrameLayout>
</com.telkom.legion.component.viewstate.LgnViewState>
`}</code></pre>
    <h4>{`Change State`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
with(binding) {
    lifecycleScope.launch { //For sample we use coroutine delay for automatic change state
        //Change to Content View
        msvSample.viewState = ViewState.CONTENT
        // Or Use Extension Function
        msvSample.showDefaultLayout()

        delay(1000)ewState.LOADING))) {
        //Do Whatever you want with layout
        delay(1000)
        //Change to Loading View
        msvSample.viewState = ViewState.LOADING
        // Or Use Extension Function
        msvSample.showLoadingLayout()

        delay(1000)
        //Change to Empty View
        msvSample.viewState = ViewState.EMPTY
        // Or Use Extension Function
        msvSample.showEmptyLayout()
    }
}
...
`}</code></pre>
    <h4>{`Modify Layout In State`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
with(binding) {
    //For sample we use state loading with layout : layout_sample_one.xml
    with(LayoutSampleOneBinding.bind(msvSample.getView(ViewState.LOADING))) {
        //Do Whatever you want with layout
    }
}
...
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View State`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:viewState`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`viewState`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set View State in Layout`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Loading View`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:loadingView`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Loading View Layout`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Empty View`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:emptyView`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Empty View Layout`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Error View`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:errorView`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set Error View Layout`}</td>
        </tr>
      </tbody>
    </table>
    <div className="divi" />
    <h2>{`References`}</h2>
    <CardGroup mdxType="CardGroup">
  <MiniCard title="MultiViewState Upstream" href="https://github.com/Kennyc1012/MultiStateView" mdxType="MiniCard" />
  <MiniCard title="ViewFlipper" href="https://developer.android.com/reference/android/widget/ViewFlipper" mdxType="MiniCard" />
    </CardGroup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      